.benefits {
    padding-top: 44px;
    padding-bottom: 44px;

    @media screen and (min-width: 1024px) {
        padding-top: 120px;
        z-index: 15;
        position: relative;
    }

    .wrap {
        padding: 0 32px;
        position: relative;

        @media screen and (min-width: 1024px) {
            padding: 0;
            padding-bottom: 156px;
        }
    }

    &__title {
        color: $dark-blue;
        line-height: 1.2;
        font-size: 32px;
        font-weight: 300;

        @media screen and (min-width: 1024px) {
            font-size: 40px;
            max-width: 440px;
        }

        strong {
            font-weight: 700;
        }
    }

    &__desc {
        margin-top: 18px;
        color: $dark-blue;
        font-size: 18px;
        line-height: 1.4;
        font-weight: 300;

        @media screen and (min-width: 1024px) {
            margin-top: 8px;
            max-width: 530px;
        }
    }

    &__button {
        margin-top: 22px;
        font-size: 14px;
        color: $white;
        background-color: $purple;
        border-radius: 48px;
        display: inline-block;
        padding: 18px;
        text-align: center;
        width: 100%;
        text-transform: uppercase;
        box-shadow: 0px 7px 64px rgba(0, 0, 0, 0.07);

        @media screen and (min-width: 1024px) {
            margin-top: 34px;
            width: auto;
            min-width: 210px;
        }
    }

    &__info {
        margin-top: 32px;
        background: $white;
        border: 1px solid $purple;
        border-radius: 16px;
        padding: 26px 30px;
        box-shadow: 0px 7px 64px rgba(0, 0, 0, 0.12);

        display: flex;
        align-items: flex-start;

        @media screen and (min-width: 1024px) {
            position: absolute;
            bottom: -42px;
            left: -2px;
            padding: 34px 32px 34px 38px;
        }

        &--icon {
            width: 20px;
            margin-right: 32px;
        }

        &--desc {
            width: calc(100% - 52px);
            color: $purple;
            font-size: 18px;
            line-height: 1.4;

            p {
                margin-bottom: 18px;

                @media screen and (min-width: 1024px) {
                    margin-bottom: 0;
                }

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }

    &__list {
        position: relative;
        margin-top: 24px;

        @media screen and (min-width: 1024px) {
            position: absolute;
            top: 0;
            left: 50%;
            transform: translateX(25%);
            margin-top: 0;
        }
    }

    &__item {
        display: flex;
        align-items: center;
        padding: 18px 24px;
        background-color: #fff;
        box-shadow: 0px 7px 64px rgba(0, 0, 0, .12);
        margin-bottom: 10px;
        border-radius: 16px;
        min-height: 82px;

        @media screen and (min-width: 1024px) {
            min-height: 100px;
            padding: 28px 32px;
            margin-bottom: 14px;
        }

        &:last-child {
            margin-bottom: 0;
        }

        &--icon {
            width: 32px;
            margin-right: 24px;
        }

        &--text {
            color: $yellow;
            font-size: 16px;

            strong {
                display: block;
                margin-top: 12px;
                color: $blue;
                font-weight: 600;

                @media screen and (min-width: 1024px) {
                    font-size: 18px;
                }
            }
        }
    }
}