.main-hero {
    position: relative;
    padding-top: 152px;

    .wrap {
        display: flex;
        flex-direction: column;
        padding: 0 32px;

        @media screen and (min-width: 1024px) {
            padding: 0;
            flex-direction: row;
            position: relative;
        }
    }

    &__content {
        @media screen and (min-width: 1024px) {
            width: 50%;
        }

    }

    &__image {
        @media screen and (min-width: 1024px) {
            width: 50%;
        }

    }

    &__title {
        font-size: 32px;
        color: $blue;
        line-height: 1.1;

        @media screen and (min-width: 1024px) {
            font-size: 56px;
            padding-top: 42px;
        }

        strong {
            font-weight: 700;

            &.purple {
                color: $purple;
            }
        }
    }

    &__desc {
        margin-top: 10px;
        font-weight: 300;
        color: $blue;
        font-size: 18px;
        line-height: 1.4;

        @media screen and (min-width: 1024px) {
            margin-top: 16px;
            max-width: 472px;
        }
    }

    &__button {
        display: inline-block;
        color: $purple;
        border: 2px solid $purple;
        width: 100%;
        padding: 18px;
        border-radius: 48px;
        text-align: center;
        margin-top: 24px;
        line-height: 0.7;
        text-transform: uppercase;

        @media screen and (min-width: 1024px) {
            margin-top: 32px;
            width: auto;
            padding: 18px 22px;
            font-size: 14px;
        }
    }

    &__image {
        margin-top: 38px;
        position: relative;

        @media screen and (min-width: 1024px) {
            margin-top: 0px;
        }

        &--desktop {
            display: none;

            @media screen and (min-width: 1024px) {
                display: block;

                -webkit-filter: drop-shadow(0px 7px 67px rgba(0, 0, 0, 0.15));
                filter: drop-shadow(0px 7px 67px rgba(0, 0, 0, 0.15));
            }
        }

        &--mobile {
            -webkit-filter: drop-shadow(0px 7px 67px rgba(0, 0, 0, 0.15));
            filter: drop-shadow(0px 7px 67px rgba(0, 0, 0, 0.15));

            @media screen and (min-width: 1024px) {
                display: none;
            }
        }

        &--float-card {
            position: absolute;

            &--1 {
                top: 0;
                left: 0;
                max-width: 38%;

                @media screen and (min-width: 1024px) {
                    top: calc(50% - 50px);
                }
            }

            &--2 {
                bottom: 78px;
                right: 0;
                max-width: 50%;

                @media screen and (min-width: 1024px) {
                    right: unset;
                    left: 42px;
                    bottom: 62px;
                }
            }
        }
    }

    &__arrow-down {
        margin-top: 28px;
        text-align: center;

        @media screen and (min-width: 1024px) {
            position: absolute;
            bottom: -18px;
            left: 294px;
            margin-top: 0;
        }
    }

    &__float-item {
        position: absolute;

        &--mobile-top {
            top: -48px;
            right: 0;
            z-index: -1;

            @media screen and (min-width: 1024px) {
                display: none;
            }
        }

        &--mobile-center {
            top: 50%;
            right: 0;
            z-index: -1;
            transform: translateY(-38%);

            @media screen and (min-width: 1024px) {
                display: none;
            }
        }
    }
}