.main-header {
    $s: &;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    box-sizing: border-box;
    padding: 16px;
    z-index: 100;

    &.active {
        @media screen and (max-width: 1023px) {
            background-color: $dark-blue;
            height: 100%;

            &::after {
                content: '';
                position: absolute;
                left: 0;
                bottom: 0;
                width: 230px;
                height: 280px;
                background-image: url('../images/float-icon-7.svg');
                animation: show 1s;
            }
        }
    }

    @media screen and (min-width: 1024px) {
        padding: 28px 0 0;
    }

    &.fixed {
        position: fixed;
        background-color: #fff;
        transform: translateY(0%);
        transition-property: transform, background-color;
        transition-duration: .5s;
        z-index: 500;
    }

    &.offset {
        position: fixed;
        transform: translateY(-100%);
    }

    .wrap {
        display: flex;
        height: 80px;
        justify-content: space-between;
        align-items: center;
        background: #FFFFFF;
        box-shadow: 0px 7px 64px rgba(0, 0, 0, 0.12);
        border-radius: 104px;
        padding: 12px 12px 12px 24px;

        @media screen and (min-width: 1024px) {
            padding: 16px 16px 16px 32px;
        }
    }

    &__logo {
        position: relative;
        z-index: 100;
        margin-top: 10px;

        @media screen and (min-width: 1024px) {
            margin-top: 6px;
        }

        img {
            max-width: 104px;

            @media screen and (min-width: 1024px) {
                max-width: 108px;
            }
        }
    }

    &__menus {
        $menus: &;
        z-index: 50;
        transform: scaleY(0);
        position: absolute;
        top: 0;
        left: 0;
        transform-origin: center top;
        padding-top: 138px;
        padding-left: 6%;
        padding-right: 5%;
        padding-bottom: 16px;
        border-radius: 32px;
        width: 100%;

        @media screen and (min-width: 1024px) {
            position: relative;
            padding: 0;
            transform: scaleY(1);
            background-color: transparent;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-left: 24px;
            box-shadow: none;

            &-item {
                display: flex !important;
            }
        }

        &.active {
            transform: scaleY(1);
            transition: transform 0.4s cubic-bezier(0.215, 0.61, 0.355, 1);
            transition-delay: 0s;

            #{$menus}-item {
                transform: translateY(0);
                opacity: 1;
                visibility: visible;
                transition: all 0.4s cubic-bezier(0.215, 0.61, 0.355, 1);
                transition-delay: .4s;
            }
        }

        #{$s}__social {
            display: flex;
            margin-top: 48px;
            padding-top: 44px;

            @media screen and (min-width: 1024px) {
                display: none;
            }

            &-item {
                margin-right: 24px
            }

            img {
                max-height: 40px;
            }
        }

        &-item {
            margin: 0;
            transform: translateY(-2rem);
            opacity: 0;
            visibility: hidden;
            display: block;

            @media(min-width: 1024px) {
                transform: translateY(0);
                opacity: 1;
                visibility: visible;
            }

        }

        li {
            margin-bottom: 34px;

            @media(min-width: 1024px) {
                margin: 0 16px 0
            }
        }

        a {
            color: $white;
            font-size: 20px;
            text-decoration: none;
            transition: .4s;

            @media(min-width: 1024px) {
                color: $blue;
                font-size: 16px;
            }

            &.active {
                color: $white;

                @media(min-width: 1024px) {
                    color: $purple;
                }
            }

            &:hover {
                color: $white;

                @media(min-width: 1024px) {
                    color: $purple;
                }
            }
        }
    }

    &__hamburguer {
        $s: &;
        position: relative;
        display: block;
        width: 48px;
        height: 48px;
        z-index: 100;
        background-color: $purple;
        border-radius: 50%;
        transition: .4s;

        &.active {
            background-color: $yellow;
        }

        @media(min-width: 1024px) {
            display: none;
        }

        &.active span {
            background-color: rgba(255, 255, 255, 0);

            &::after,
            &::before {
                background-color: #fff;
                transition-delay: 0s, 0.2s;
            }

            &::before {
                top: 0;
                transform: rotate(45deg);
            }

            &::after {
                bottom: 0;
                transform: rotate(-45deg);
            }
        }

        span {
            display: block;
            background-color: #fff;
            width: 22px;
            height: 2px;
            margin-top: -1px;
            font: 0 / 0 a;
            text-shadow: none;
            color: transparent;
            transition: background-color 0.2s ease-in-out;
            position: absolute;
            left: calc(50% - 11px);
            top: 50%;
            bottom: auto;

            &::after,
            &::before {
                content: "";
                width: 100%;
                height: 100%;
                background-color: inherit;
                transition-duration: 0.2s, 0.2s;
                transition-delay: 0.2s, 0s;
                position: absolute;
                left: 0;
            }

            &::before {
                top: -8px;
                transition-property: top, transform;
            }

            &::after {
                bottom: -8px;
                transition-property: bottom, transform;
            }
        }
    }

    &__login {
        display: none;

        @media screen and (min-width: 1024px) {
            display: block;

        }

        a {
            display: inline-block;
            background-color: $purple;
            color: $white;
            text-transform: uppercase;
            min-width: 140px;
            text-align: center;
            padding: 12px;
            border-radius: 48px;
            font-size: 14px;
            line-height: 1;
        }
    }

    @keyframes showAfter {
        to {
            opacity: 0;
            left: -128px;
        }

        from {
            opacity: 1;
            left: 0;
        }
    }
}