.main-contact {
    position: relative;
    background-color: $light-purple;
    padding-top: 152px;
    padding-bottom: 138px;
    overflow: hidden;

    @media screen and (min-width: 1024px) {
        padding-top: 192px;
        overflow: hidden;
    }

    .wrap {
        position: relative;
        padding: 0 32px;

        @media screen and (min-width: 1024px) {
            display: flex;
            align-items: flex-start;
            padding: 0;
        }
    }

    &__left {
        display: flex;
        justify-content: space-between;

        @media screen and (min-width: 1024px) {
            flex-direction: column;
        }
    }

    &__text {
        padding-right: 128px;
    }

    &__title {
        font-size: 32px;
        line-height: 1.2;
        color: $blue;
        font-weight: 600;

        @media screen and (min-width: 1024px) {
            font-size: 64px;
            max-width: 428px;
        }
    }

    &__desc {
        margin-top: 10px;
        color: $purple;
        font-size: 18px;
        line-height: 1.2;
        font-weight: 300;

        @media screen and (min-width: 1024px) {
            margin-top: 22px;
            max-width: 344px;
            line-height: 1.25;
        }
    }

    &__illustration {
        position: absolute;
        top: 0;
        right: 32px;

        @media screen and (min-width: 1024px) {
            position: relative;
            right: 0;
            margin-top: 32px;
        }

        &-img-desk {
            display: none;

            @media screen and (min-width: 1024px) {
                display: block;
            }
        }

        &-img-mobile {
            @media screen and (min-width: 1024px) {
                display: none;
            }
        }
    }

    &__list {
        position: relative;
        background: #FFFFFF;
        box-shadow: 0px 7px 67px rgba(0, 0, 0, 0.15);
        border-radius: 16px;
        margin-top: 30px;
        padding: 24px;
        z-index: 15;

        @media screen and (min-width: 1024px) {
            margin: 16px 0 16px 46px;
            padding: 40px;
        }
    }

    &__item {
        $s: &;
        border-radius: 8px;
        border: 1px solid $off-white;
        padding: 18px 24px;
        display: flex;
        align-items: flex-start;
        margin-bottom: 24px;

        @media screen and (min-width: 1024px) {
            padding: 28px 32px;
        }

        &:first-child {
            #{$s}--data {
                a {
                    display: block;

                    @media screen and (min-width: 1024px) {
                        display: inline;
                    }
                }
            }
        }

        &:last-child {
            margin-bottom: 0;
        }

        &--icon {
            width: 64px;

            @media screen and (min-width: 1024px) {

                width: 76px;
            }
        }

        &--content {
            width: calc(100% - 64px);
        }

        &--title {
            font-size: 18px;
            line-height: 1.2;
            color: $purple;
            font-weight: 600;
        }

        &--data {
            margin-top: 14px;
            line-height: 1.4;
            color: $blue;
            font-weight: 300;

            @media screen and (min-width: 1024px) {
                margin-top: 4px;
            }

            a {
                color: $blue;
                text-decoration: underline;

                &[href^="mailto:"]{
                    display: block;
                }
            }

            p {
                margin-bottom: 12px;

                @media screen and (min-width: 1024px) {
                    margin-bottom: 4px;
                }

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }

    &__float-icon {
        position: absolute;
        z-index: 5;

        &--one {
            display: none;

            @media screen and (min-width: 1024px) {
                display: block;
                bottom: -212px;
                left: 35%;
            }

            @media screen and (min-width: 1920px) {

                left: 38%;
            }
        }

        &--two {
            bottom: -60px;
            right: -62px;
            max-width: 284px;

            @media screen and (min-width: 1024px) {
                bottom: unset;
                top: 0;
                max-width: unset;
            }
        }
    }
}