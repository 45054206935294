.midia {
    position: relative;
    margin-top: 100px;

    @media screen and (min-width: 1024px) {
        margin-top: 126px;
    }

    &--seja-um-parceiro {
        @media screen and (min-width: 1024px) {
            margin-top: 56px;
        }

    }

    &::after {
        content: '';
        background-color: $light-purple;
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 82%;
        z-index: -1;

        @media screen and (min-width: 1024px) {
            height: 65%;
        }
    }

    .wrap {
        padding: 0;
    }

    &__title {
        color: $blue;
        text-align: center;
        font-size: 32px;
        font-weight: 300;

        @media screen and (min-width: 1024px) {
            text-align: left;
            font-size: 40px;
        }

        strong {
            flex-wrap: 700;
        }
    }

    &__list {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;

        position: relative;
        padding: 40px 32px 70px;
        margin-top: -12px;

        @media screen and (min-width: 1024px) {
            padding: 62px 50px 70px;
        }
    }

    &__item {
        $item: &;
        width: 170px;
        height: 88px;
        background-color: $white;
        box-shadow: 0px 7px 67px rgba(0, 0, 0, 0.1);
        border-radius: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 30px 28px;
        cursor: pointer;

        @media screen and (min-width: 1024px) {
            padding: 30px 16px;
        }

        &--empty {
            width: 170px;
            height: 88px;
        }

        &.active {
            position: relative;

            &::after {
                content: '';
                position: absolute;
                left: 24px;
                bottom: 0;
                transform: translateY(100%);
                z-index: -1;
                width: 1px;
                height: 22px;
                background-color: $yellow;

                @media screen and (min-width: 1024px) {
                    left: 50%;
                    transform: translateX(-50%) translateY(100%);
                    height: 36px;

                }
            }

            img {
                filter: contrast(1);
            }
        }

        img {
            max-height: 34px;
            object-fit: contain;
            filter: contrast(0);
            transition: .4s;
        }
    }

    &__content-list {
        padding: 52px 56px 40px;
        margin-top: -88px;
        position: relative;

        @media screen and (min-width: 1024px) {
            padding: 90px 50px 76px;
        }
    }

    &__content-item {
        flex-wrap: wrap;
        display: none;

        @media screen and (min-width: 1024px) {
            flex-wrap: nowrap;
            align-items: flex-start;
        }

        &.active {
            display: flex;
            opacity: 0;
            animation: show 1s;
            animation-iteration-count: 1;
            animation-fill-mode: forwards;
        }

        &--data {
            color: $dark-blue;
            padding-right: 24px;
            width: 74px;

            @media screen and (min-width: 1024px) {
                padding-right: 36px;
                width: 85px;
            }

            .month {
                display: inline-block;
                font-size: 18px;
                font-weight: 300;
                letter-spacing: 0.08rem;
            }

            .year {
                display: inline-block;
                font-size: 20px;
                font-weight: 700;
                margin-top: 2px;
            }
        }

        &--text {
            color: $dark-blue;
            width: calc(100% - 74px);

            @media screen and (min-width: 1024px) {
                width: unset;
            }

            p {
                line-height: 1.37;
                font-weight: 300;
            }
        }


        &--link {
            position: relative;
            flex: 1;
            margin-left: 74px;
            margin-top: 26px;
            width: 100%;
            padding: 16px 18px 10px;
            text-transform: uppercase;
            color: $purple;
            border: 2px solid $purple;
            border-radius: 48px;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
            line-height: 0.7;

            @media screen and (min-width: 1024px) {
                min-width: 264px;
                margin-top: 0;
                font-size: 14px;
            }

            @media screen and (max-width: 447px) {
                font-size: 12px;
            }

            span {
                display: flex;
                align-items: center;
                margin-top: -6px;

                &::after {
                    content: '';
                    display: block;
                    width: 24px;
                    height: 24px;
                    background-color: $purple;
                    -webkit-mask-image: url(../images/icon-arrow-left.svg);
                    mask-image: url(../images/icon-arrow-left.svg);
                    margin-left: 10px;
                }
            }
        }
    }

    @keyframes show {
        0% {
            opacity: 0;
        }

        100% {
            opacity: 1;
        }
    }

    &__float-item {
        position: absolute;
        left: 0;
        bottom: 0;

        @media screen and (min-width: 1024px) {
            bottom: -60%;
        }
    }
}