.divider {
    margin-top: 28px;

    @media screen and (min-width: 1024px) {
        margin-top: 76px;
    }

    .wrap {
        margin: 0 auto;
        padding: 0 32px;

        @media screen and (min-width: 1024px) {
            padding: 0 0 0 104px;
            max-width: 820px;

        }
    }

    &__title {
        font-size: 32px;
        line-height: 1.2;
        color: $blue;
        font-weight: 300;

        @media screen and (min-width: 1024px) {
            font-size: 40px;
        }

        strong {
            color: $purple;
            font-weight: 700;
        }

        br {
            @media screen and (min-width: 768px) {
                display: none;
            }
        }
    }

    &__desc {
        margin-top: 18px;
        color: $blue;
        font-size: 18px;
        line-height: 1.4;
        font-weight: 300;
    }

    &__button {
        display: inline-block;
        color: $white;
        background-color: $purple;
        width: 100%;
        padding: 18px;
        border-radius: 48px;
        text-align: center;
        margin-top: 24px;
        line-height: 0.7;
        text-transform: uppercase;
        box-shadow: 0px 7px 64px rgba(0, 0, 0, 0.07);

        @media screen and (min-width: 1024px) {
            margin-top: 32px;
            width: auto;
            padding: 18px 54px;
            font-size: 14px;
            position: relative;
            z-index: 50;
        }
    }

    &__cards {
        position: relative;
        padding: 40px 0 70px;
        display: flex;
        flex-direction: column;
        padding-left: 28px;

        @media screen and (min-width: 1024px) {
            flex-direction: row;
            max-width: #{$container}px;
            margin: 0 auto;
            padding: 60px 0 70px 288px;
            margin-top: -20px;
            overflow: inherit;
        }

        &-item {
            padding: 32px;
            border-radius: 16px;
            box-shadow: 0px 7px 67px rgba(0, 0, 0, 0.15);
            width: 292px;
            margin: 0 6px;
            background-color: $white;

            @media screen and (min-width: 1024px) {
                margin: 0 8px;
            }
        }

        &-text {
            margin-top: 14px;

            &--min {
                display: block;
                color: $yellow;
                height: 1.2;
            }

            &--normal {
                display: block;
                color: $blue;
                font-weight: 600;
                height: 1.2;
                margin-top: 6px;

                @media screen and (min-width: 1024px) {
                    font-size: 18px;
                }
            }
        }
    }
}