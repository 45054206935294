.internal-banner {
    position: relative;
    margin-top: 148px;
    overflow: hidden;

    display: flex;
    flex-direction: column-reverse;
    align-items: flex-start;

    padding-bottom: 48px;

    @media screen and (min-width: 1024px) {
        margin-top: 0;
        flex-direction: row;
        min-height: 704px;
        overflow: unset;
        padding-bottom: 0;
    }

    .wrap {
        padding: 0 32px;
        width: 100%;

        @media screen and (min-width: 1024px) {
            margin-top: 192px;
            display: flex;
            justify-content: flex-end;
            padding: 0 28px;

        }
    }

    &__content {
        @media screen and (min-width: 1024px) {
            max-width: 480px;
        }
    }

    &__title {
        font-size: 32px;
        line-height: 1.1;
        color: $blue;
        font-weight: 300;

        @media screen and (min-width: 1024px) {
            font-size: 56px;
        }

        strong {
            color: $purple;
            font-weight: 700;
        }
    }

    &__desc {
        margin-top: 14px;
        font-size: 18px;
        line-height: 1.4;
        font-weight: 300;
        color: $dark-blue;

        @media screen and (min-width: 1024px) {
            margin-top: 18px;
            font-size: 20px;
            max-width: 342px;
        }
    }

    &__button {
        margin-top: 22px;
        font-size: 14px;
        color: $white;
        background-color: $purple;
        border-radius: 48px;
        display: inline-block;
        padding: 18px;
        text-align: center;
        width: 100%;
        text-transform: uppercase;
        box-shadow: 0px 7px 64px rgba(0, 0, 0, 0.07);

        @media screen and (min-width: 1024px) {
            margin-top: 22px;
            width: auto;
            min-width: 272px;
        }
    }

    &__image {
        margin-top: 32px;
        max-width: 398px;
        margin-right: 32px;
        border-bottom-right-radius: 92px;
        overflow: hidden;
        height: 384px;

        @media screen and (min-width: 1024px) {
            position: absolute;
            top: 0;
            left: calc(50% + 12px);
            transform: translateX(-100%);
            max-width: unset;
            margin-top: 0px;
            margin-right: 0;
            height: unset;
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;

            @media screen and (min-width: 1024px) {
                max-width: none;
                object-fit: none;
                width: unset;
                height: unset;

            }
        }
    }

    &__arrow-down {
        display: none;


        @media screen and (min-width: 1024px) {
            display: block;
            text-align: center;
            position: absolute;
            bottom: 0;
            left: calc(50% + 92px);
            margin-top: 0;
        }
    }

    &__float-item {
        position: absolute;

        &--message {
            right: 0;
            bottom: -12px;
            max-width: 200px;
            animation: spinner 16s linear infinite;

            @media screen and (min-width: 1024px) {
                max-width: 406px;
                right: -80px;
                bottom: -240px;
            }
        }

    }


    @keyframes spinner {
        0% {
            transform: rotate(0deg);
        }

        100% {
            transform: rotate(360deg);
        }
    }
}