.faq {
    padding-top: 46px;

    @media screen and (min-width: 1024px) {
        padding-top: 90px;
    }

    .wrap {
        padding: 0 56px;

        @media screen and (min-width: 1024px) {
            max-width: 858px;
            padding: 0;
        }

        @media screen and (max-width: 447px) {
            padding: 0 18px;
        }
    }

    &__pre-title {
        display: block;
        font-weight: 300;
        color: $dark-blue;
        text-transform: uppercase;
        letter-spacing: 0.08em;
        text-align: center;

        @media screen and (min-width: 1024px) {
            font-size: 18px;
        }
    }

    &__title {
        color: $blue;
        font-size: 32px;
        margin-top: 8px;
        font-weight: 300;
        line-height: 1.1;
        text-align: center;

        @media screen and (min-width: 1024px) {
            font-size: 40px;
            margin-top: 14px;
        }

        strong {
            color: $blue;
            font-weight: 700;
        }
    }

    &__list {
        margin-top: 40px;

        @media screen and (min-width: 1024px) {
            margin-top: 78px;
        }

    }

    &__item {
        $item: &;
        border-bottom: 1px solid $off-white;
        margin-bottom: 32px;

        &:last-child {
            margin-bottom: 0;
        }

        &--question {
            position: relative;
            font-weight: 700;
            color: $dark-blue;
            display: flex;
            align-items: center;
            padding-bottom: 36px;
            transition: .4s;
            line-height: 1.2;
            padding-right: 34px;

            #{$item}.active & {
                padding-bottom: 0px;
            }

            @media screen and (min-width: 1024px) {
                font-size: 18px;
                cursor: pointer;
            }
        }

        &--icon {
            width: 32px;
            height: 32px;
            background-color: $dark-blue;
            -webkit-mask-image: url(../images/icon-plus.svg);
            mask-image: url(../images/icon-plus.svg);
            transition: .4s;

            position: absolute;
            right: 0;

            #{$item}.active & {
                background-color: $yellow;
                transform: rotate(45deg);
            }
        }

        &--answer {
            color: $dark-blue;
            font-size: 18px;
            line-height: 1.4;
            padding: 0;
            max-height: 0;
            transition: .4s;
            overflow: hidden;
            // padding: 16px 0;
            opacity: 0;

            #{$item}.active & {
                max-height: 1610px;
                padding: 16px 0;
                opacity: 1;
            }

            p {
                margin-bottom: 10px;

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }
}