.the-future {
    background-color: $light-purple;
    position: relative;
    margin-top: 8px;
    padding: 32px 0;

    @media screen and (min-width: 1024px) {
        margin-top: 52px;
        margin-bottom: 40px;
        padding-top: 80px;
        padding-bottom: 60px;
    }

    &__title {
        font-weight: 100;
        color: $purple;
        font-size: 48px;
        line-height: 1.2;
        padding-right: 44px;

        @media screen and (min-width: 1024px) {
            font-size: 72px;
            max-width: 776px;
            margin: 0 auto;
        }

        strong {
            font-weight: 500;
        }
    }

    &__list {
        margin-top: 32px;
        padding-left: 48px;
        z-index: 10;

        @media screen and (min-width: 1024px) {
            max-width: 636px;
            margin: 60px auto 0;
            padding-left: 0;
            margin-bottom: 0;
        }

    }

    &__item {
        $s: &;
        position: relative;
        padding-bottom: 16px;
        margin-bottom: 20px;
        border-bottom: 1px solid $off-white;

        &:last-child {
            border-bottom: 0;

            &::after {
                display: none;
            }
        }

        &::before {
            content: '';
            width: 8px;
            height: 8px;
            background-color: $yellow;
            border-radius: 50%;

            position: absolute;
            left: -40px;
            top: 8px;
            z-index: 10;

            @media screen and (min-width: 1024px) {
                left: -69px;

            }
        }

        &::after {
            content: '';
            width: 1px;
            height: 120%;
            background-color: $off-white;

            position: absolute;
            left: -36px;
            top: 8px;
            z-index: 5;

            @media screen and (min-width: 1024px) {
                left: -66px;
            }
        }

        &--title {
            font-size: 18px;
            line-height: 1.2;
            color: $purple;
            font-weight: 600;
        }

        &--desc {
            margin-top: 14px;

            p {
                color: $dark-blue;
                line-height: 1.4;
                font-weight: 300;
            }
        }
    }

    &__float-item {
        display: none;
        z-index: -1;

        @media screen and (min-width: 1024px) {
            position: absolute;
            display: block;
            z-index: 1;

            &--one {
                left: 100%;
                top: -264px;
            }

            &--two {
                right: 100%;
                bottom: -80px;
            }
        }
    }
}