html {
    scroll-behavior: smooth;
}

* {
    box-sizing: border-box;
}

body {
    font-family: "Alexandria", sans-serif;
    width: 100%;
    overflow-x: hidden;

    &.no-scroll {
        overflow: hidden;
    }
}

textarea {
    font-family: "Alexandria", sans-serif;
}

strong {
    font-weight: 800;
}

em {
    font-style: italic;
}

.container {
    position: relative;
    max-width: #{$container}px;
    margin: 0 auto;
    padding-left: 16px;
    padding-right: 16px;

    @media(min-width: 1024px) {
        padding: 0;
    }
}

.header-section {
    max-width: 768px;
    margin: 0 auto;
    text-align: center;
    margin-bottom: 32px;

    &__title {
        font-size: 24px;
        line-height: 1.4;
        margin-bottom: 12px;

        @media(min-width: 1024px) {
            font-size: 32px;
        }
    }

    &__description {
        p {
            margin-bottom: 8px;
            line-height: 1.5;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}