.alert {
    position: fixed;
    width: 100%;
    padding: 0 16px;
    bottom: 16px;
    left: 0;
    z-index: 500;

    @media screen and (min-width: 1024px) {
        bottom: 56px;
    }

    &.hidden {
        animation: closePopUp 400ms forwards;
    }

    .wrap {
        border-radius: 16px;
        padding: 24px;
        box-shadow: 0px 7px 64px rgba(0, 0, 0, 0.12);
        background-color: $white;
        display: flex;
        align-items: flex-start;

        @media screen and (min-width: 1024px) {
            max-width: 1064px;
            align-items: center;
            padding: 16px 32px;
        }
    }

    &__icon {
        width: 40px;
        margin-right: 16px;
    }

    &__text {
        font-size: 14px;
        color: $dark-blue;
        line-height: 1.4;
        width: calc(100% - 96px);
        margin-right: 16px;

        @media screen and (min-width: 1024px) {
            font-size: 16px;
            text-align: center;
        }
    }

    &__close {
        width: 24px;
        cursor: pointer;
    }

    @keyframes closePopUp {
        0% {
            opacity: 1;
        }

        99% {
            opacity: 0;
        }

        100% {
            display: none;
        }
    }
}