.timeline {
    margin-top: -22px;

    @media screen and (min-width: 1024px) {
        margin-top: 72px;
    }

    .wrap {
        padding: 0;

        @media screen and (min-width: 1024px) {
            display: flex;
            align-items: flex-start;
        }
    }

    &__left {
        display: flex;
        flex-direction: column-reverse;

        @media screen and (min-width: 1024px) {
            flex-direction: column;
            max-width: 488px;

            position: relative;
        }
    }

    &__image {
        border-bottom-right-radius: 92px;
        height: 383px;
        object-fit: cover;
        margin-right: 32px;
        @media screen and (min-width: 1024px) {
            height: 507px;
            position: absolute;
            right: 0;
            bottom: 0;
            margin-bottom: 0;
            transform: translateY(100%);
            max-width: none;
        }
    }

    &__title {
        margin-top: 32px;
        font-size: 32px;
        line-height: 1.2;
        color: $purple;
        font-weight: 300;
        padding: 0 46px;

        @media screen and (min-width: 1024px) {
            font-size: 40px;
            margin-top: 0;
            padding: 0;
            padding-right: 60px;
        }

        strong {
            font-weight: 700;
        }
    }

    &__desc {
        margin-top: 28px;
        font-size: 18px;
        line-height: 1.2;
        font-weight: 300;
        padding: 0 44px;
        color: $purple;

        @media screen and (min-width: 1024px) {
            margin-top: 26px;
            padding: 0;
            margin-bottom: 44px;
        }
    }

    &__list {
        margin-top: 38px;
        padding: 0 68px 0 46px;

        @media screen and (min-width: 1024px) {
            margin-top: 5px;
            padding: 0 68px 0 120px;
        }
    }

    &__item {
        $item: &;
        display: flex;
        position: relative;

        &:last-child {
            #{$item}--desc {
                padding-bottom: 0;

                @media screen and (min-width: 1024px) {
                    padding-bottom: 26px;
                }
            }
        }

        &:hover {
            #{$item}--year {
                color: $yellow;
            }
        }

        &--year {
            font-size: 24px;
            color: #D0D0D0;
            line-height: 1.2;
            font-weight: 500;
            margin-right: 24px;
            max-width: 56px;
            transition: .4s;

            @media screen and (min-width: 1024px) {
                max-width: unset;
                margin-right: 0;
                inline-size: fit-content;
                transform: rotate(-90deg);
                font-size: 32px;

                position: absolute;
                left: -36px;
                top: 22px;
            }
        }

        &--desc {
            padding-bottom: 31px;
            padding-left: 24px;
            border-left: 1px solid $off-white;

            @media screen and (min-width: 1024px) {
                max-width: 230px;
                padding-left: 24px;
                margin-left: 36px;
                padding-bottom: 64px;
            }

            p {
                font-size: 14px;
                line-height: 1.4;
                color: $dark-blue;

                strong {
                    font-weight: 700;
                }
            }
        }
    }
}